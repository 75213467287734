<!--
 * @Author: your name
 * @Date: 2020-12-25 18:07:08
 * @LastEditTime: 2021-03-19 09:07:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\mini\printSkillEn.vue
-->
<template>
  <div class="box" v-html="mas"></div>
</template>

<script>
import { post } from "../../utils/http.js";
import { Toast } from "vant";
export default {
  name: "printSkill",
  data() {
    return {
      lang: "EN",
      watt: "1.6",

      mas: "",
    };
  },
  mounted() {
    let { lang, watt } = this.$route.params;
    this.lang = lang;
    this.watt = watt;
    let id = null;
    switch (lang) {
      case "EN":
        document.title = "Playing computer skills";
        id = 4;
        break;
      case "JP":
        document.title = "ミニBluetoothレーザー彫刻機";
        id = 6;
        break;
      case "TC":
        document.title = "玩機技巧-Mini鐳射雕刻機";
        id = 5;
        break;
      default:
        document.title = "玩机技巧-Mini激光雕刻机";
        id = 3;
    }

    // 直接获取
    post("/api/dkj/create", { id })
      .then((res) => {
        let { status, data, msg } = res;
        if (status !== 0) {
          Toast(msg);
        } else {
          let { banner } = data;
          this.mas = banner;
        }
      })
      .catch(() => {
        Toast("获取失败");
      });
  },
};
</script>

<style scoped>
.box >>> h1,
.box >>> h2,
.box >>> h3,
.box >>> h4,
.box >>> h5,
.box >>> pre {
  margin-top: 1em;
  margin-bottom: 1.16667em;
}
.box >>> h3,
.box >>> h4,
.box >>> h5,
.box >>> pre {
  font-size: 30px;
}
.box >>> pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.box >>> li {
  font-size: 30px;
}
.box {
  min-height: 100vh;
  border: 1px solid #f0f0f0;
  padding: 10px 16px 20px;
  word-break: break-word;
  line-height: 1.6;
  color: #121212;
  box-sizing: border-box;
  text-align: left;
}
.box >>> h1,
.box >>> h2 {
  clear: left;
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}
.box >>> h2 {
  font-size: 30px;
  text-align: left;
}
.box >>> p {
  margin: 20px 0;
  font-size: 28px;
  line-height: 50px;
}
.box >>> img {
  display: block;
  margin: 20rpx auto;
  max-width: 100%;
}
</style>
